const questionsMock = [
  {
    category: "Within the last 36 months, have you had:",
    category_id: 19001,
    questions: [
      {
        question: 'Atrial Fibrillation or "Afib"?',
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Any internal cancer or malignant melanoma?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Pancreatitis diagnosis or treatment?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "Hepatitis C? (If yes, has it been cured through biologic pharmaceutical therapy?)",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Hodgkin's Disease?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
    ],
  },
  {
    category: "Within the last 24 months, have you had:",
    category_id: 19002,
    questions: [
      {
        question: "A heart valve disorder diagnosis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "A Respiratory or Lung Disorder that required the use of three or more medications, or oxygen, or both?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Use of oxygen for any other reason?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
    ],
  },
  {
    category: "Within the last 12 months:",
    category_id: 19003,
    questions: [
      {
        question:
          "Has anyone on this application had a Heart Attack or Stroke, or had a Pacemaker implanted?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
    ],
  },
  {
    category: "During the prior 12 months:",
    category_id: 10004,
    questions: [
      {
        question:
          "Does anyone on this application use or have you used illegal drugs?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
    ],
  },
  {
    category: "Do you have any of the following:",
    category_id: 19005,
    questions: [
      {
        question: "AIDs, ARC or HIV?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Addison's?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Alzheimer's or Dementia?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Body Mass Index of 14 or less or 41 or more?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "Cardiomyopathy (dilated, hypertrophic or restrictive) or Cardiomegaly ('Enlarged Heart')?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Cerebral Palsy?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Cirrhosis of the Liver?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Congestive Heart Failure?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "Connective Tissue Disorder (including Polymyositis and Dermatomyositis)? CREST Syndrome?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Crohn's Disease or Ulcerative Colitis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "An implantable cardioverter defibrillator or 'ICD'?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "Diabetic complications (e.g. insulin dependence, retinopathy, neuropathy, peripheral vascular or arterial disease, heart artery blockage, history of heart attack or stroke)?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Hepatitis B?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Huntington's disease?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Any Kidney Disease, End Stage Renal Disease or dialysis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Leukemia?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Lou Gehrig's Disease or 'ALS'?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Lupus?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Lymphoma?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Multiple Myeloma?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Multiple Sclerosis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Muscular Dystrophy?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Myasthenia Gravis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Paget's Disease?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Any Paralytic condition?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Parkinson's Disease?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Peripheral Neuropathy?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Peripheral Vascular Disease?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Renal Insufficiency?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Rheumatoid Arthritis?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Scleroderma?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question: "Sjogren's Syndrome?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
      {
        question:
          "Any type of Transplant (including Bone Marrow, Organ or Stem Cell)?",
        answers: ["yes", "no"],
        expected_answer: "no",
      },
    ],
  },
];

export default questionsMock;
